const en = {
	// Register
	1: 'Register',
	2: 'Please enter your username',
	3: 'The username must start with a letter, contain only letters, underscores, and numbers, and  be between 5 and 10 in length',
	4: 'Please enter your password',
	5: 'The password can only contain letters and numbers, and must be between 6 and 15 in length',
	6: 'Please enter your password again',
	7: 'Please enter your email',
	8: 'The email address must contain the @ character',
	9: 'The left side of the mailbox @ can be letters, underscores, numbers, dot. and small diagonal bar number- except the initial letter',
	10: 'The right side of the mailbox@must have a dot.',
	11: 'Must start with a letter, an underline, a number between @ and. , and except the initial letter, other can be a letter, an underline, a number, a dot. a small diagonal bar number-',
	12: 'Male',
	13: 'Female',
	14: 'Please enter your invite code',
	15: 'Click here to get invite code',
	16: 'Register',
	17: 'Return to login',
	18: 'Click here to leave a message',
	// Index
	19: 'Dear Crazy Tank players, welcome to login',
	20: 'After logging in, you can view your specific information (including record, teams and assets)',
	21: 'There is a slight delay in the display of personal information on the official website. Please refer to the game data',
	22: 'Sign up',
	23: 'Download',
	24: 'Recharge',
	25: 'The Beginners Guide',
	26: 'Game Master Advanced ',
	27: 'Official Forum',
	28: 'Configuration Requirements',
	29: 'Combat Methods',
	30: 'Coming Soon',
	31: 'Item Introduction',
	32: 'Map Introduction',
	33: 'Tank Introduction',
	34: 'News',
	35: 'Notice',
	36: 'Media reviews',
	37: 'Player strategy',
	38: 'Forum hot post',
	39: 'More media reviews',
	40: 'More',
	// Apply
	41: 'Select',
	42: 'Revoke',
	43: 'Approve',
	44: 'Team Name',
	45: 'Leader',
	46: 'Applicant',
	47: 'Level',
	48: 'Create Time',
	49: 'Approved',
	50: 'Rejected',
	51: 'Canceled',
	// Create
	52: 'Create Team(',
	53: ' Coin',
	54: 'Recharge Now',
	55: 'Logout',
	56: 'The team name consists of 3-12 characters',
	57: 'Color',
	58: 'Preview',
	59: 'Create',
	60: 'You have joined a team,if you want to create a team, you must quit or disband the original team first',
	61: 'Create team successed',
	// Detail
	62: 'Delete team',
	63: 'Leave team',
	64: 'Operation',
	65: 'Delete',
	66: 'Alias name',
	67: 'Level',
	68: 'Ranking',
	69: 'Score',
	70: 'Win Rate',
	71: 'Last Login',
	72: 'Team member deleted',
	73: 'Are you sure you want to disband the team?',
	74: 'Hint',
	75: 'Confirm',
	76: 'Cancel',
	77: 'Delete team successed',
	78: 'Canceled',
	79: 'Are you sure you want to quit the team?',
	80: 'You have successfully quit the team',
	// Guild Index
	81: 'Select',
	82: 'Join',
	83: 'Name',
	84: 'Team leader',
	85: 'Number of players',
	86: 'Create Time',
	87: 'Team score',
	88: 'Application has been submitted, please wait for the team leader to review!',
	// Recharge
	89: 'Recharge',
	90: 'How To Recharge',
	91: 'Recharged Amount',
	92: 'Immediately receive ',
	93: 'Cash',
	94: 'For points sponsorship, please contact',
	95: 'Statement of donation support',
	96: 'Dear friends,',
	97: 'The FortressTank as the last holy land of FortressRed2, we need your support and protection, and we will continue to improve the game is features and fix any problems that may arise.',
	98: 'In order to continue to maintain the operation of the FortressTank, the following expenses are all free sponsorship, hereby declared!',
	99: 'Thank you for your support for the FortressTank in the future!',
	100: 'The Team of FortressTank January 1, 2021',
	101: 'Donation in progress, please be patient and wait for the results…',
	102: 'close',
	103: 'PayPal donation',
	104: 'Alipay scan code donation',
	105: 'QR code donation',
	106: 'The donation has been made',
	// MotifyPassword
	107: 'Please enter your old password',
	108: 'Please enter your password',
	109: 'The password can only contain letters and numbers, and must be between 6 and 15 in length',
	110: 'Please enter your password again',
	111: 'Modify',
	112: 'Please enter your email address',
	113: 'Password modification completed',
	// Login
	114: 'User Login',
	115: 'Log in',
	// SideBar
	116: 'Home Page',
	117: 'Team',
	118: 'Team List',
	119: 'Team Detail',
	120: 'Create Team',
	121: 'Join/Apply',
	122: 'Account',
	123: 'Modify Password',
	124: 'Money',
	125: 'WinCount',
	126: 'LoseCount',
	127: 'Account Status',
	// 
	128: 'Click to download',
	129: 'Note',
	130: 'Must be installed C++ Runtime',
	131: 'Please click the button.',
	132: 'Gift.',
	133: ' Score',
	134: 'Recharge for Friend',
	135: 'Friend\'s nickname',
	136: 'Please enter your alias name',
	137: 'nickname must be between 1 and 6 in length.',
	138: 'NickName',
	139: 'Modify NickName',
	140: 'NickName Type',
	141: 'Normal(300 Coin)',
	142: 'Special(1500 Coin)',
	143: 'nickname modification completed',
	144: 'check',
	145: 'Register completed',
	146: 'Title',
	147: 'Author',
	148: 'Forgot Password',
	149: 'Next',
	150: 'Sent',
	151: 'Input new password',
	152: 'Search',
	153: 'Find Id',
	154: 'Please enter your new email address',
	155: 'Please enter your new email address again',
	156: 'You write different email address twice',
	157: 'Change email address',
	158: 'Email address modification completed',
	159: 'New email address',
	160: 'Email address',
	161: 'Change guild name',
	162: 'Change guild color',
	163: 'Are you sure you want to change the guild name?',
	164: 'Are you sure you want to change the guild color?',
	165: 'You have changed the guild name successed.',
	166: 'You have changed the guild color successed.',
	167: 'Transference',
	168: 'Are you want to transfer the guild?',
	169: 'You have transfered the guild successed.',
	170: 'Order amout',
	171: 'The minimum is 1,500 coins,increase by 1,500 coins each time',
	172: 'The minimum is 1,000 score,increase by 1,000 score each time',
	// ErrorCode
	1000: '成功',
	1001: '失败',
	1002: '用户名不符合规则',
	1003: '密码不符合规则',
	1004: '邮箱不符合规则',
	1005: '未加入任何战队',
	1006: '身份证不符合规则',
	1007: '用户名已存在',
	1008: '用户名不正确',
	1009: '密码不正确',
	1010: '账号已锁定',
	1011: '性别不符合规则',
	1012: '已经加入战队',
	1013: '已经申请过战队',
	1014: '铜勋章以上才可以建立战队',
	1015: '战队名称重复',
	1016: '战队不存在',
	1017: '未加入战队',
	1018: '队长才能审核',
	1019: '不是该战队的成员，不能查看列表',
	1020: '战队名称不正确',
	1021: '没有战队加入申请表',
	1022: '订单不正确',
	1023: '金币数量不正确，创建战队需要3000金币',
	1024: '邀请码不正确',
	1025: '邀请码已使用',
	1026: '队长不能够删除',
	1057: '未知的错误',
	1058: '无访问权限',
	1059: '昵称不正确',
	1060: '昵称已存在',
	1061: '昵称不符合规则',
	1062: '金币数量不正确',
	1063: '开始时间不正确',
	1064: '结束时间不正确',
	1065: '金币不正确',
	1066: '二维码类型不正确',
	1067: '二维码不正确',
	1068: '验证码不正确',
	1069: '邮箱不正确',
	1070: '超过每日重置次数上限',
}

export default {
	...en,
}